import { addActionBarItem, addNavMenuItem } from '@vendure/admin-ui/core';
import { map } from 'rxjs/operators';
import { environment } from './environments/environment'

const notVisibleState = ["PaymentSettled", 'OrderApproved', 'Cancelled', 'StockApproved']

export const getDeliveryNote = async() => {
    const deliveryNoteURL = `${environment.SHIPSY_URL}?reference_number=AMAL22853`;
    const headers = {
        'x-api-key': environment.SHIPSY_KEY || "",
        'accept': 'application/json',
        'content-type': 'application/json'
    }
    const options = {
        method: 'GET', // HTTP method (GET, POST, PUT, DELETE, etc.)
        headers: headers // Passing the headers object
    };
    fetch(deliveryNoteURL, options)
    .then(response => {
        console.log(response)
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        
        return response.json(); // Parsing the response data as JSON
    })
    .then(data => {
        console.log(data); // Handling the data
    })
    .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
    });
}

export default [
    addActionBarItem({
        id: 'print-invoice',
        label: 'Download Invoice',
        locationId: 'order-detail',
        onClick(event, context) {
            getDeliveryNote()
        },
        buttonState: context => {
            return context.entity$.pipe(
                map(order => ({
                    disabled: false,
                    visible: !!order?.state && !notVisibleState.includes(order?.state) && order.type === "Seller",
                })),
            );

        }
    })
];

